//---------------------------------------
// This scss is used to import all necessary scss files.
//---------------------------------------

// Import vendor assets
@import "vendors";

// Import application assets
@import "layout";
@import "modules/modules";
