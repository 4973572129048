/* Login */
body.login {
  /*background: rgba(0,0,0,0.056) !important;*/
  background: rgb(242,242,242) !important;
  background: radial-gradient(ellipse at center, #F7F7F7 0%, #ECECEC 100%) !important;
  width: 100% !important;
  min-width: 100% !important;
}

.account-wall .form-signin
{
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.account-wall .form-signin .form-control
{
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.account-wall .form-signin .form-control:focus
{
  z-index: 2;
}
.account-wall .form-signin input[type="text"]
{
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.account-wall .form-signin input[type="password"]
{
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.account-wall
{
  /*margin-top: 15rem;*/
  padding: 20px 0px 20px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f7f7f7;
  -moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
}

.account-wall .field {
  position: relative;
}

.account-wall .field i {
  position: absolute;
  top: 15px;
  left: 8px;
  z-index: 10;
  color: rgba(0,0,0,0.6);
}

.account-wall .field input {
  padding-left: 35px !important;
}

.account-wall .error_message {
  color: red;
}
