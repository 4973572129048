//----------------------------
// Storage homepage css code.
//----------------------------

.row > .storageGroups + .hide {
    width: 3%;
}

.row > .storageGroups + .hide > .row {
    padding: 0px 0px 0px 15px;
}

.storageGroups {
    /*min-width: 235px;*/
    padding: 0px 0px 24px 0px;
    margin: 0px 0px -10px 0px;
    box-shadow: 0px -10px 10px -2px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.storageGroups > .row {
    margin: 0px;
    padding: 10px 0px 10px 20px;
}

.storageItems {
    padding: 0px;
}

.storageGroupsShow {
    display: none;
    position: fixed;
    top: 180px;
    left: 0px;
    padding: 0px 0px 0px 0px;
    z-index: 100;
}

.storageItems.fullScreen {
    padding: 0px 0px 0px 10px;
    width: 100%;
}

.storageItems table {
    font-size: 11px;
    font-family: 'Roboto', 'Open Sans', sans-serif;
}

.storageItems thead {
    background: white;
}

.storageItems thead th {
    line-height: 2 !important;
    font-size: 13px;
    font-weight: 400;
}

.storageItems td, .storageItems th {
    text-align: center;
    vertical-align: middle !important;
}

.storageItems tbody tr {
    cursor: pointer;
}

.storageItems tbody tr:nth-of-type(even) {
    background-color: #F1F1F1;
}

.storageItems tbody tr:nth-of-type(even):hover {
    background-color: #EAEAEA;
}

.storageItems tbody td:nth-of-type(3) {
    font-weight: 500;
}

.storageItems .dropdown-menu {
    margin: -23px 0px 0px -170px;
}

.storageItems .dropdown-menu > li > a {
    padding: 3px 6px;
}

.storageItemPreview {
    padding: 10px 0px 0px 10px;
    margin: 0px 0px 0px 0px;
    box-shadow: 2px 0px 5px 2px rgba(0,0,0,0.2);
    width: 100%;
    position: fixed;
    bottom: 0px;
    height: 350px;
    background: white;
    z-index: 99;
    font-family: 'Roboto', 'Open Sans', sans-serif;
    font-size: 87%;
    display: none;
}

.storageItemPreview .close {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    z-index: 10;
}

.storageItemPreview .edit {
    position: absolute;
    top: 10px;
    right: 40px;
}

.storageItemPreview table {
    display: table;
    width: 97%;
}
.storageItemPreview table thead, .storageItemPreview table tbody {
    float: left;
    width: 100%;
}
.storageItemPreview table tbody {
    overflow: auto;
    height: 255px;
}

.storageItemPreview table thead {
    font-weight: 500;
    text-align: center;
    box-shadow: inset 0 -6px 10px -10px #000000;
    padding: 5px;
}

.storageItemPreview table thead + tbody {
    height: 228px;
}

.storageItemPreview table tr {
    width: 100%;
    display: table;
    text-align: left;
}

.storageItemPreview table.centerTableText tr {
    text-align: center;
}

.tree ul {
    margin-left: 10px;
}

.tree li {
    margin: 0px 0;
    list-style-type: none;
    position: relative;
    padding: 5px 5px 0px 5px;
}
.tree li::before {
    content:'';
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    right: auto;
    left: -10px;
    border-left: 1px solid #ccc;
    bottom: 20px;
}
.tree li::after {
    content:'';
    position: absolute;
    top: 20px;
    width: 15px;
    height: 20px;
    right: auto;
    left: -10px;
    border-top: 1px solid #ccc;
}
.tree li a {
    display: inline-block;
    border: 1px solid #ccc;
    padding: 5px 10px;
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}
/*Remove connectors before root*/
.tree > ul > li::before, .tree > ul > li::after {
    border: 0;
}
/*Remove connectors after last child*/
.tree li:last-child::before {
    height: 20px;
}
/*Time for some hover effects*/

/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover, .tree li a:hover+ul li a {
    background: #F4F2F2;
    color: #000;
    border: 1px solid #94a0b4;
}
/*Connector styles on hover*/
.tree li a:hover+ul li::after, .tree li a:hover+ul li::before, .tree li a:hover+ul::before, .tree li a:hover+ul ul::before {
    border-color: #94a0b4;
}

.tree .active, .tree .active:hover {
    background: #007399;
    color: white;
}
