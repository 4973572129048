@import "~bootstrap-sass/assets/stylesheets/bootstrap/variables";

// $bootstrap-sass-asset-helper: true;

//
// Overwritten variables
// --------------------------------------------------

//== Colors

$brand-primary: darken(#008CBA, 6.0%); // #337ab7
$brand-success: #51A739; //#5cb85c !default;

//== Components
//** Width of the `border` for generating carets that indicator dropdowns.

//== Tables
//** Background color used for `.table-hover`.
$table-bg-hover: #EAEAEA;

//== Dropdowns
//
//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: #333333;
//** Dropdown menu `border-color`.
$dropdown-border: darken($dropdown-bg, 5%); //rgba(0,0,0,.15) !default;

//** Dropdown link text color.
$dropdown-link-color: #FFFFFF;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($dropdown-link-color, 5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg: darken($dropdown-bg, 5%);

//== Navbar
//
//##
// Basics of a navbar
$navbar-height: 45px;

$navbar-default-color: #FFF;
$navbar-default-bg: #333333;

// Navbar links
$navbar-default-link-color: white;
$navbar-default-link-hover-color: #FFF;
$navbar-default-link-hover-bg: #444444;
$navbar-default-link-active-color: #FFF;
$navbar-default-link-active-bg: $brand-primary;

//== Iconography
$icon-font-path: "../../fonts/bootstrap/";
