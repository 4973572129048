.topNav {
    width: 100%;
    top: 0px;
    min-height: 10px;
    font-size: 13px;
    font-family: Roboto, 'Open Sans', sans-serif;
}

.topNav.affix {
    box-shadow: 0px 1px 5px rgba(0,0,0,0.6)
}

.topNav .container-fluid {
    padding-left: 0px;
    margin-bottom:  -1px;
}

.topNav .logoutIcon {
    color: rgba(250, 0, 0, 0.9);
}

.topNav .caret {
    margin-left: 5px;
    color: #747474;
}

.topNav .dropdown-menu {
    padding: 0px;
}

.topNav .dropdown:hover > .dropdown-menu {
    display: block;
    margin: 0px 0px 0px 0px;
    background: #333;

    .caret {
        color: white;
    }
}

.topNav .dropdown > .dropdown-menu:hover {
    background: #333;
}

.topNav .dropdown-menu > li > a {
    padding: 10px 11px;
}

.topNav .dropdown-left > .dropdown-menu {
    margin: -40px 158px 0px 0px !important;
}

.topNav .dropdown-right > .dropdown-menu {
    margin: -40px 0px 0px 158px !important;
}

.topNav .dropdown-left .caret, .topNav .dropdown-right .caret {
    border-left: 4px dashed;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
}

.topNav .nav > li > a {
    padding-left: 7px;
    padding-right: 7px;
}


.dropdown-menu-right-arrow:before {
    position: absolute;
    top: 6px;
    right: -7px;
    display: inline-block;
    border-left: 6px dashed;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    content: '';
}

.dropdown-menu-right-arrow:after {
    position: absolute;
    top: 6px;
    right: -7px;
    display: inline-block;
    border-left: 6px dashed;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    content: '';
}
