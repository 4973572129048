//---------------------------------------
// Global style should be kept here
//---------------------------------------

html, body, ul, ol, li, p,
h1, h2, h3, h4, h5, h6,
form, a {
    margin: 0;
    padding: 0;
    border: 0;
}

html {
    height: 100%; /* for footer */
}

body {
    font-size: 14px;
    line-height: 1.3em;
    font-family: Roboto, "Open Sans", "Helvetica Nueue", sans-serif;
    color: #000000;
    background-color: #f7f6f6 !important;
    height: 100%; /*for footer*/
    min-width: 1150px !important;
}

body > header {
    background: white;
}

img {
    border: 0px;
}

fieldset {
    border: 1px solid #D8D8D8;
    border-radius: 4px;
}

form label {
    font-weight: 400;
}

table th {
    font-weight: 500;
}

.spacer {
    clear: both;
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
}


@page {
    size: 8.3in 11.8in;
    margin: 1cm 0.5cm 1cm 0.5cm;
}

@media print {
    a[href]:after {
        content: none !important;
    }

    #time {
        display: none;
    }
}

/* Source: http://snipplr.com/view/10979/css-cross-browser-word-wrap */
.wordwrap {
    /*white-space: pre-wrap;      *//* CSS3 */
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -pre-wrap;     /* Opera <7 */
    white-space: -o-pre-wrap;   /* Opera 7 */
    word-wrap: break-word;      /* IE */
}

#wrapper {
    min-height: 100%;
    width: 100%;
    /*overflow-x: hidden;*/
    /*margin: 0 auto -42px;*/ /* the bottom margin is the negative value of the footer's height */
    padding: 198px 0px 0px 0px;
    margin: -218px 0px 39px 0px;
    /*margin: -218px 0px 0px 0px;*/
}

.push {
    height: 92px; /* .push must be the same height as .footer  height=footer_height+footer_margin+footer_border*/
}


#header {
    background-color: #ffffff;
    height: 80px;
    border-bottom: 1px solid #CE0909;
}


#logo {
    float: left;
    margin-top: 15px;
}

#time {
    float: right;
    font-family: Roboto, sans-serif, serif;
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: right;
    margin: 20px 15px 0px 0px;
}

/*footer*/
#footer {
    width: 100%;
    border-top: 1px solid #cccccc;
    background-color: #eae9e9;
    height: 40px; /* .push must be the same height as .footer */

    p {
        margin: 10px auto 0 auto;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
    }
}

/* Content */
@keyframes developer-fade {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1; }
}
@-webkit-keyframes developer-fade {
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1; }
}
@-o-keyframes developer-fade{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity: 1; }
}
@-moz-keyframes developer-fade{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity: 1; }
}

.developer-blink {
    animation: developer-fade 5s infinite;
    -webkit-animation: developer-fade 5s infinite;
    -moz-animation: developer-fade 5s infinite;
    -o-animation: developer-fade 5s infinite;

    position: absolute;
    top: 0px;
    width: 100%;
    background: #2196F3;
    color: white;
    font-family: 'Roboto', 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 300;
    text-align: center;
}


.loading-dot-one {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.0s;
    animation: dot 1.3s infinite;
    animation-delay: 0.0s;
}

.loading-dot-two {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.2s;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
}

.loading-dot-three {
    opacity: 0;
    -webkit-animation: dot 1.3s infinite;
    -webkit-animation-delay: 0.3s;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
}

@-webkit-keyframes dot {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes dot {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
}

.pageHeader {
  background: #007399;//#008CBA;
  padding: 10px 0px 10px 0px;
  color: white;
  z-index: 10;
  position: relative;
  box-shadow: inset 0 -11px 13px -10px rgba(0,0,0,0.5);
  -moz-box-shadow: inset 0 -11px 13px -10px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 -11px 13px -10px rgba(0,0,0,0.5);
}

.pageHeader h3 {
    font-weight: 300;
}

.pageHeader h3 a {
    color: #EDEDED;
    text-decoration: underline;
}

.pageHeader h3 a:hover {
    color: white;
    text-decoration: none;
}

.container-centered {
    margin: auto;
}

.container-500 {
    max-width: 500px;
}

.container-600 {
    max-width: 600px;
}

.container-900 {
    max-width: 900px;
}

.container-960 {
    max-width: 960px;
}

.container-1000 {
    max-width: 1000px;
}

.container-1000-centered {
    max-width: 1000px;
    margin: auto;
}

.container-1200 {
    max-width: 1200px;
}

.container-1200-centered {
    max-width: 1200px;
    margin: auto;
}


.bs-callout {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #eee;
    border-left-width: 5px;
    border-radius: 3px;
}

.bs-callout h1, .bs-callout h2, .bs-callout h3, .bs-callout h4, .bs-callout h5 {
    margin: 0px 0px 10px 0px;
    padding: 0px 0px 5px 0px;
    border-bottom: 1px dashed #D8D8D8;
}

.bs-callout-info {
    border-left-color: #1b809e;
}

.bs-callout-danger {
    border-left-color: #D8726C;
}

.bs-callout-danger h1, .bs-callout-danger h2, .bs-callout-danger h3, .bs-callout-danger h4, .bs-callout-danger h5 {
    color: #DD1212;
}

.popover {
    font-family: 'Roboto Condensed', 'Open Sans', sans-serif;
}

.tab-pane {
    padding: 5px;
}

.text-middle {
    vertical-align: middle;
}

.table {
    thead th {
        vertical-align: middle !important;
    }

    td {
        vertical-align: middle !important;
    }

    .icons {
        .dropdown-menu {
            margin: -25px 0px 0px -180px;
        }

        .dropdown-menu > li > a {
            padding: 3px 10px;

            > i {
              margin-right: 5px;
            }
        }
    }
}

// sticky table header.
.tableFloatingHeaderOriginal {
    background: white;
}