// Core variables and mixins
@import "variables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "bootstrap-datepicker";

// Bootstrap datepicker
@import "./bootstrap-datepicker";

// Bootstrap touchspin
@import "./bootstrap-touchspin";

// Customizations
.badge-danger {
  background-color: #d90a0a;
}