
.profile {
    padding: 10px 0px 0px 0px;
    width: 300px !important;
}

.profile label {
    float: left;
    text-align: right;
    width: 140px !important;
}

.profile input {
    float: right;
    width: 140px;
}