@media print {
    .invoice {
        width: 100% !important;
        height: 100% !important;
        max-width: 100% !important;
        margin-top: 40px !important;
        padding-right: 20px !important;
    }

    .invoiceItems {
        padding: 0px 40px 0px 0px;
    }

    .invoice th, .invoice tr, .invoice td {
        border: 1px solid black !important;
    }
}

.invoice {
    font-size: 84%;
    line-height: 2.2em;
    font-family: Verdana, "Times New Roman", Times, serif;
    margin-top: 20px;
}

.invoiceHeader {
    border-bottom: 1px solid black;
    margin-bottom: 15px;
}

.invoiceType {
    clear: both;
    font-weight:bold;
    font-size:40px;
    padding: 10px 0 15px 0;
    color: #D4D4D4 !important;
}

.invoice th {
    background-color:#eeeeee;
}

.key {
    font-weight: bold;
    position: relative;
}