.receiptHeader {
    color: white;
}
.receiptHeader small {
    color: white;
    font-weight: 300;
}
.bold {
    font-weight: 500;
}

.receiptItemModal .modal-dialog {
    width: 700px;
}

@media print {
    body {
        font-size: 2rem;
    }

    .receipt {
        width: 100% !important;
        height: 100% !important;
        max-width: 100% !important;
        padding: 0px 20px 0px 20px;
    }


    .receipt th, .receipt td {
        border: 1px solid #D8D8D8;
    }
    .receipt table th{
        font-size: 1.5rem;
    }
}