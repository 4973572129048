//---------------------------------------------------
// This scss is used to import all third-party css
//---------------------------------------------------

// Bootstrap
@import "bootstrap/bootstrap";
@import "bootstrap/menu";

// Fonts
// --- Open Sans
$OpenSansPath: "~open-sans-fontface/fonts" !default;
@import "~open-sans-fontface/sass/variables";
@import "~open-sans-fontface/sass/mixins";
@import "~open-sans-fontface/sass/Light";

// --- Roboto
$roboto-font-path: '~roboto-fontface/fonts' !default;
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-light";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-regular";
@import "~roboto-fontface/css/roboto/sass/roboto-fontface-medium";

// --- Fontawesome
// --- TODO: Switch from v4 to v5 naming convention.
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~@fortawesome/fontawesome-free/css/v4-shims.css";

// CSS Animation
@import "~animate.css";

// Select2
@import "~select2/select2";
@import "~select2/select2-bootstrap";

.select2-container .select2-choice {
  height: 31px;
}
.select2-container .select2-choice .select2-arrow {
  width: 20px;
}
.select2-container .select2-choice .select2-arrow b,
.select2-container .select2-choice > .select2-chosen {
  margin-top: 2px;
}
.form-group > .input-group-addon + .select2-container {
  display: table-cell;

  .select2-choice {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

}



// JQuery
@import "~jquery-ui/themes/base/jquery-ui.css";
@import "./jquery/jquery.ui";
