//
//
// jQuery UI style fixes
//
//

.ui-widget {
  font-size: 0.9em !important;
}

.ui-spinner-input {
  margin: 0px !important;
  padding: 7px 5px 7px 5px;
}

