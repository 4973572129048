#adimeks_storagebundle_item_description_counter:after,
#adimeks_storagebundle_item_description_counter:before{
  content:' ';
  display:block;
  clear: both;
}

#adimeks_storagebundle_item_description_counter {
  float: right;
  margin: 5px 0px 0px 0px;
}


#adimeks_storagebundle_item_description_count {
  border-radius: 4px 4px 4px 4px;
  background: none repeat scroll 0 0 #058DC7;
  color: #FFFFFF;
  font-size: 10px;
  padding: 2px 6px;
  position: relative;
  top: -1px;
  vertical-align: middle;
}

.tagit-new input {
  height: 27px;
}

.tab-content > .active {
  padding: 10px 20px 10px 17px;
  background: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
}

.fileinput-button {
  position: relative;
  overflow: hidden;
}
.fileinput-button input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  font-size: 200px;
  direction: ltr;
  cursor: pointer;
}

/* Fixes for IE < 8 */
@media screen\9 {
  .fileinput-button input {
    filter: alpha(opacity=0);
    font-size: 100%;
    height: 100%;
  }
}

.file {
  padding: 10px 10px 0px 20px;
  margin: 0px 10px 10px 0px;
  border: 1px solid #D8D8D8;
  border-radius: 4px;
  width: 48.3%;
  height: 120px;
}

.file .thumbnail {
  max-width: 130px;
  max-height: 100px;
}

.file .buttons {
  padding: 10px 30px 0px 0px;
}

.file .buttons button {
  margin-right: 5px;
}

.file .buttons .progress {
  margin-bottom: 5px;
}

.file .title {
  margin: 0px 15px 0px -15px;
  overflow: hidden;
  padding: 0px 0px 10px 0px;
  font-size:15px;
}

.ui-autocomplete {
  width: 400px !important;
}


