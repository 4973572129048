//-----------------------------------------
// All css code for partners nomenclature
//-----------------------------------------

.partners_form {
    padding: 10px 100px 0 0;
    width: 550px;
    margin: 0px auto;
}

.partners_form label {
    float: left;
    text-align: right;
    width: 180px !important;
}

.partners_form input[type="text"] {
    float: right;
    width: 350px;
}
